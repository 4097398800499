import React, { useState, useRef } from 'react';

import { Box } from '@mui/material';

import posed from 'react-pose';

const PoseBox = posed(Box)({
	boxAberto: {
		applyAtStart: { display: 'initial' },
		height: () => window.innerHeight,
		width: () => window.innerWidth,
		top: 0,
		left: 0,
		opacity: 0.9,
	},
	boxFechado: {
		applyAtEnd: { display: 'none' },
		height: ({ inicial }) => inicial && inicial.height,
		width: ({ inicial }) => inicial && inicial.width,
		top: ({ inicial }) => inicial && inicial.top,
		left: ({ inicial }) => inicial && inicial.left,
		opacity: 0,
	},
});

const PoseImage = posed(Box)({
	imagemAberto: {
		applyAtStart: { position: 'fixed' },
		height: ({ inicial }) =>
			inicial &&
			((inicial.naturalHeight * window.innerWidth) / inicial.naturalWidth >
			window.innerHeight
				? window.innerHeight
				: (inicial.naturalHeight * window.innerWidth) / inicial.naturalWidth) -
				16,
		width: ({ inicial }) =>
			inicial &&
			((inicial.naturalHeight * window.innerWidth) / inicial.naturalWidth >
			window.innerHeight
				? (inicial.naturalWidth * window.innerHeight) / inicial.naturalHeight
				: window.innerWidth) - 16,
		top: ({ inicial }) =>
			inicial &&
			((inicial.naturalHeight * window.innerWidth) / inicial.naturalWidth >
			window.innerHeight
				? 0
				: (window.innerHeight -
						(inicial.naturalHeight * window.innerWidth) /
							inicial.naturalWidth) /
				  2) + 8,

		left: ({ inicial }) =>
			inicial &&
			((inicial.naturalHeight * window.innerWidth) / inicial.naturalWidth >
			window.innerHeight
				? (window.innerWidth -
						(inicial.naturalWidth * window.innerHeight) /
							inicial.naturalHeight) /
				  2
				: 0) + 8,
		borderRadius: '8px',
	},
	imagemFechado: {
		applyAtEnd: { position: 'static' },
		height: ({ inicial }) => inicial && inicial.height,
		width: ({ inicial }) => inicial && inicial.width,
		top: ({ inicial }) => inicial && inicial.top,
		left: ({ inicial }) => inicial && inicial.left,
	},
});

const ImagemPonto = (props) => {
	const ref = useRef();

	const [aberto, setAberto] = useState(false);

	const [inicial, setInicial] = useState();

	const [loading, setLoading] = useState(true);

	const style = {
		...props.style,
		zIndex: 2,
		maxWidth: '100%',
		maxHeight: '100%',
	};

	function updateInicial() {
		if (
			ref.current &&
			(!ref.current.style.position || ref.current.style.position === 'static')
		) {
			const bounding = ref.current.getBoundingClientRect();

			setInicial({
				naturalHeight: ref.current.naturalHeight,
				naturalWidth: ref.current.naturalWidth,
				height: bounding.height,
				width: bounding.width,
				top: bounding.top,
				bottom: bounding.bottom,
				left: bounding.left,
				right: bounding.right,
			});
		}
	}

	return (
		<>
			{loading && (
				<Box
					width="100%"
					height="100%"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<div className="load-3">
						<div className="circle-loading"></div>
						<div className="circle-loading"></div>
						<div className="circle-loading"></div>
					</div>
				</Box>
			)}

			<PoseImage
				component="img"
				ref={ref}
				{...props}
				onClick={(e) => {
					if (typeof props.onClick === 'function') {
						props.onClick(e);
					}
					if (!aberto) {
						updateInicial();
					}

					setAberto(!aberto);
				}}
				style={style}
				onLoad={() => {
					updateInicial();
					setLoading(false);
				}}
				inicial={inicial}
				pose={inicial ? (aberto ? 'imagemAberto' : 'imagemFechado') : null}
			/>

			<PoseBox
				bgcolor="#232323"
				onClick={() => {
					if (aberto) {
						setAberto(false);
					}
				}}
				inicial={inicial}
				pose={inicial ? (aberto ? 'boxAberto' : 'boxFechado') : null}
				display="none"
				position="fixed"
				zIndex="1"
			/>
		</>
	);
};

export default ImagemPonto;
