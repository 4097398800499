import React from 'react';

import { useGlobal } from 'reactn';

import { useRouteMatch } from 'react-router-dom';

import ReactMapboxGl from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { AttributionControl } from 'mapbox-gl';

import LayerPontos from './LayerPontos';
import LayerPosicaoUsuario from './LayerPosicaoUsuario';
// import LayerOnibusAndando from './;components/LayerOnibusAndando';
import LayerTerminais from './LayerTerminais';
import LayerLinha from './LayerLinha';
import LayerMarcadorDestino from './LayerMarcadorDestino';

import { Box } from '@mui/material';
import MarkerProximoHorarioPonto from './MarkerProximoHorarioPonto';

const MapBox = ReactMapboxGl({
	accessToken:
		'pk.eyJ1IjoiYi1idW5oYWsiLCJhIjoiY2psNjExM3o1MG40ZzNsbnRvY3cyY2JtciJ9.NtNszpibCfg1fUAPrS33Sg',
	refreshExpiredTiles: false,
	injectCss: false,
	attributionControl: false,
	logoPosition: 'bottom-right',
});

const Mapa = ({ dark }) => {
	const [, setGlobalState] = useGlobal();

	const [maxBounds] = useGlobal('maxBounds');

	const [mapaCentro] = useGlobal('mapaCentro');
	const [zoom] = useGlobal('zoom');
	const [fitBounds] = useGlobal('fitBounds');

	const [menuHeight] = useGlobal('menuHeight');

	const [offset] = useGlobal('offset');

	const pontoRoute = useRouteMatch('/ponto/:pontoId');

	const pontoSelecionado = pontoRoute && pontoRoute.params.pontoId;

	function onZoom(map) {
		if (zoom[0] !== map.getZoom()) {
			setGlobalState({ zoom: [map.getZoom()] });
		}
	}

	function onDragEnd(map) {
		const { lng, lat } = map.getCenter();

		if (mapaCentro[0] !== lng || mapaCentro[1] !== lat) {
			setGlobalState({ mapaCentro: [lng, lat], localizacaoLock: false });
		}
	}

	return (
		<Box position="fixed" top="0" right="0" left="0" bottom="0">
			<MapBox
				style={
					dark
						? 'mapbox://styles/b-bunhak/cl3qzz5c6001n14qkonkho2rk'
						: 'mapbox://styles/b-bunhak/cl3r0k0m3002o14l19yjarrad'
				}
				containerStyle={{
					height: '100%',
					width: '100%',
				}}
				onStyleLoad={(ref) => {
					ref.addControl(new AttributionControl(), 'bottom-right');
				}}
				center={mapaCentro}
				zoom={zoom}
				onZoomEnd={onZoom}
				onDragEnd={onDragEnd}
				fitBounds={fitBounds}
				fitBoundsOptions={{
					padding: {
						top: 16 + menuHeight + window.innerHeight * offset,
						bottom: 16,
						right: 20,
						left: 20,
					},
					offset: [0, -(window.innerHeight * offset) / 2],
				}}
				flyToOptions={
					pontoSelecionado
						? {
								offset: [0, -(window.innerHeight * offset) / 2],
						  }
						: null
				}
				maxBounds={maxBounds}
			>
				<LayerTerminais />

				<LayerPontos />

				<LayerPosicaoUsuario />

				<LayerLinha />

				<LayerMarcadorDestino />

				<MarkerProximoHorarioPonto />
			</MapBox>
		</Box>
	);
};

export default Mapa;
