import React from 'react';
import { useGlobal } from 'reactn';
import { useHistory } from 'react-router-dom';

import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import { ButtonBase, Typography, Box, SvgIcon } from '@mui/material';

import HoverBox from './HoverBox';

import { ReactComponent as TodasLinhas } from '../images/map.svg';

const MenuInferior = () => {
	const history = useHistory();

	const [pontoSelecionado] = useGlobal('pontoSelecionado');
	const [trajetoria] = useGlobal('trajetoria');
	const [localizacao] = useGlobal('localizacao');
	const [localizacaoLock, setLocalizacaoLock] = useGlobal('localizacaoLock');
	const [mapaCentro] = useGlobal('mapaCentro');

	if (pontoSelecionado || trajetoria) {
		return null;
	}

	return (
		<React.Fragment>
			<Box position="fixed" bottom="0" left="0" zIndex={1}>
				<ButtonBase
					onClick={() => setLocalizacaoLock(true)}
					sx={{
						color:
							localizacaoLock &&
							localizacao &&
							mapaCentro[0] === localizacao.longitude &&
							mapaCentro[1] === localizacao.latitude
								? 'primary.main'
								: 'text.primary',
						m: 1,
						display: 'flex',
						bgcolor: 'background.paper',
						borderRadius: 6,
						boxShadow: 12,
					}}
				>
					<HoverBox
						flex={1}
						display="flex"
						justifyContent="center"
						alignItems="center"
						px={2}
						py={1}
						borderRadius="inherit"
					>
						<Box component={GpsFixedIcon} height="1.5rem" width="1.5rem" />
					</HoverBox>
				</ButtonBase>

				<ButtonBase
					onClick={() => {
						history.push('/ponto/TERMINAL');
					}}
					sx={{
						m: 1,
						display: 'flex',
						bgcolor: 'background.paper',
						border: 0,
						borderColor: 'primary.main',
						borderRadius: 6,
						boxShadow: 12,
					}}
				>
					<HoverBox
						flex={1}
						display="flex"
						justifyContent="center"
						alignItems="center"
						px={2}
						py={1}
						borderRadius="inherit"
						font-saze="0.75rem"
					>
						<Box
							component={TodasLinhas}
							height="1.5rem"
							width="1.5rem"
							mr={1}
						/>

						<Typography variant="h6" component="div">
							Linhas
						</Typography>
					</HoverBox>
				</ButtonBase>
			</Box>
		</React.Fragment>
	);
};

export default MenuInferior;
