import React from 'react';
import { useGlobal } from 'reactn';
import { GeoJSONLayer, Source, Layer } from 'react-mapbox-gl';
import { compact } from 'lodash-es';

import PontoIcon from '../images/icone-ponto-onibus.svg';

const pontoImg = new Image(50, 50);
pontoImg.src = PontoIcon;

const Linha = ({ linha, linhaSelecionada, onClick }) => (
	<React.Fragment>
		<GeoJSONLayer
			data={{
				type: 'Feature',
				properties: {},
				geometry: linha.rota,
			}}
			lineLayout={{
				'line-join': 'round',
				'line-cap': 'round',
			}}
			linePaint={{
				'line-color': linha.cor,
				'line-width': 4,
			}}
			before="pontos"
			lineOnClick={onClick}
		/>

		<Source
			id="geo"
			geoJsonSource={{
				type: 'geojson',
				data: linha.rota ? linha.rota : [],
			}}
		/>
		{linhaSelecionada && (
			<Layer
				before="terminais"
				id="flexinhas"
				type="symbol"
				sourceId="geo"
				layout={{
					'symbol-placement': 'line',
					'text-field': '>',
					'text-size': ['interpolate', ['linear'], ['zoom'], 24, 28, 44, 120],
					'symbol-spacing': [
						'interpolate',
						['linear'],
						['zoom'],
						12,
						30,
						22,
						160,
					],
					'text-keep-upright': false,
				}}
				paint={{
					'text-color': linha.cor,
					'text-halo-color': 'hsl(55, 11%, 96%)',
					'text-halo-width': 2,
				}}
			/>
		)}
	</React.Fragment>
);

const LayerLinha = () => {
	const [linhas] = useGlobal('linhas');
	const [linhaSelecionada, setLinhaSelecionada] = useGlobal('linhaSelecionada');
	const [pontoSelecionadoLinhas] = useGlobal('pontoSelecionadoLinhas');
	const [trajetoria] = useGlobal('trajetoria');

	const visiveis = linhaSelecionada
		? [linhaSelecionada]
		: trajetoria
		? trajetoria.linhas
		: pontoSelecionadoLinhas;

	return compact(
		visiveis.map((id) => {
			const linha = linhas.dados[id];

			if (linha) {
				return (
					<Linha
						key={id}
						linha={linha}
						linhaSelecionada={id === linhaSelecionada}
						onClick={() => setLinhaSelecionada(id)}
					/>
				);
			}

			return null;
		})
	);
};

export default LayerLinha;
