import { Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const HoverBox = styled(Box)(({ theme }) => ({
	'&:hover': {
		backgroundColor: alpha(theme.palette.primary.main, 0.5),
	},
}));

export default HoverBox;
