import React, { useState, useEffect, useMemo } from 'react';
import {
	getFirestore,
	doc,
	setDoc,
	arrayUnion,
	arrayRemove,
} from 'firebase/firestore';
import { useGlobal } from 'reactn';

import { useRouteMatch, useHistory } from 'react-router-dom';

import ListarHorarios from './ListarHorarios';
import { daysByBinary } from '../functions/data';
import { ReactComponent as IconTopCircle } from '../images/icon-top-circle.svg';
import { ReactComponent as IconX } from '../images/x-circle.svg';
import { ReactComponent as IconMinify } from '../images/icon-minify.svg';
import { ReactComponent as IconVoltar } from '../images/voltar.svg';

import dayjs from 'dayjs';

import { map, sortBy, compact, find } from 'lodash-es';

import posed from 'react-pose';

import ImagemPonto from './ImagemPonto';

import { ButtonBase, Box, TextField, Paper, SvgIcon } from '@mui/material';

import DatePicker from '@mui/lab/DatePicker';

import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

import { jaPassou } from '../functions/data';
import ImagemPonto2 from './ImagemPonto2';

const ContainerPose = posed(Paper)({
	aberto: {
		y: 0,
		transition: 'tween',
		height: '55%',
	},
	fechado: {
		y: '150%',
		transition: 'tween',
		afterChildren: true,
	},
	alterando_data: {
		y: 0,
		transition: 'tween',
		height: '90%',
	},
	minimizado: {
		y: 0,
		transition: 'tween',
		height: '2.5rem',
	},
});

let now = dayjs();
function timeAddMinutes(time, min) {
	let [h, m] = time.split(':').map((t) => Number(t));
	return now
		.set(`hour`, h)
		.set(`minute`, m)
		.set(`second`, 0)
		.add(min, `minute`)
		.format(`HH:mm`);
}

const ModalPonto = () => {
	const [auth] = useGlobal('auth');
	const [usuario] = useGlobal('usuario');

	const [mapaOffset, setMapaOffset] = useGlobal('offset');
	const [mapaCentro, setMapaCentro] = useGlobal('mapaCentro');

	const [linhas] = useGlobal('linhas');

	const [linhaSelecionada, setLinhaSelecionada] = useGlobal('linhaSelecionada');
	const [pontoSelecionadoLinhas] = useGlobal('pontoSelecionadoLinhas');
	const [proximoHorarioPontoMarker, setProximoHorarioPontoMarker] = useGlobal(
		'proximoHorarioPontoMarker'
	);

	const [imagens] = useGlobal('imagens');

	const [feriados] = useGlobal('feriados');

	const [data, setData] = useState(dayjs());

	const [minimizado, setMinimizado] = useState(false);

	const [setorSelecionado, setSetorSelecionado] = useState(null);

	const pontoRoute = useRouteMatch('/ponto/:pontoId');

	const history = useHistory();

	const pontoSelecionado = pontoRoute && pontoRoute.params.pontoId;

	const linhasPonto = useMemo(
		() => pontoSelecionadoLinhas.map((id) => linhas.dados[id]),
		[pontoSelecionadoLinhas]
	);

	const poseState = pontoSelecionado
		? minimizado
			? 'minimizado'
			: 'aberto'
		: 'fechado';

	const mostarEstrelaFavorito =
		auth && pontoSelecionado && pontoSelecionado !== 'TERMINAL';

	const isFavorito =
		pontoSelecionado &&
		usuario &&
		Array.isArray(usuario.pontosFavoritos) &&
		usuario.pontosFavoritos.includes(pontoSelecionado);

	useEffect(() => {
		if (pontoSelecionado) {
			let offset = 0.55;
			if (poseState === 'minimizado') {
				offset = 0.08;
			}

			if (mapaOffset !== offset) {
				setMapaOffset(offset);
			}

			if (linhasPonto.length === 0) {
				setMapaCentro([...mapaCentro]);
			}
		}
	}, [pontoSelecionado, poseState, mapaOffset, setMapaOffset]);

	function setFavorito(pontoId) {
		if (auth) {
			return setDoc(
				doc(getFirestore(), 'usuarios', auth.uid),
				{
					pontosFavoritos: isFavorito
						? arrayRemove(pontoId)
						: arrayUnion(pontoId),
				},
				{ merge: true }
			);
		}
	}

	const setores = useMemo(
		() =>
			sortBy(
				linhasPonto.reduce((setores, linha) => {
					let minutosIncrementarNoHorario = 0;

					let setor;

					if (pontoSelecionado === 'TERMINAL') {
						setor = linha.setores[0];
					} else {
						setor = find(linha.setores, { pontos: [{ id: pontoSelecionado }] });
					}

					if (setor) {
						const dia =
							feriados.findIndex(
								(feriado) => feriado.ddmmyyyy == data.format(`YYYY-MM-DD`)
							) !== -1
								? 7
								: data.format('d');

						const horarios = sortBy(
							compact(
								map(setor.horarios, (bin, horario) => {
									if (daysByBinary(bin)?.[dia]?.active) {
										if (minutosIncrementarNoHorario > 0) {
											horario = timeAddMinutes(
												horario,
												minutosIncrementarNoHorario
											);
										}

										return {
											horario,
											jaPassou: jaPassou(horario, data),
										};
									}
								})
							),
							'horario'
						);

						let proximoHorario = horarios.find(({ jaPassou }) => !jaPassou);

						if (proximoHorario) {
							proximoHorario = proximoHorario.horario;
						}

						setores.push({
							...setor,
							linha,
							horarios,
							proximoHorario,
						});
					}

					return setores;
				}, []),
				['linha.nome', 'nome']
			),
		[linhasPonto, data]
	);

	useEffect(() => {
		if (setorSelecionado || setorSelecionado === 0) {
			setProximoHorarioPontoMarker({
				pontoId: pontoSelecionado,
				horario: setores[setorSelecionado].proximoHorario,
			});
		}
	}, [setores, setorSelecionado]);

	return (
		<ContainerPose
			pose={poseState}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				p: 0,
				m: 0,
				mx: { xs: 0, lg: '25%' },
				position: 'fixed',
				bottom: '0',
				width: { xs: '100%', lg: '50%' },
				zIndex: '2',
				borderRadius: 2,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				background: (theme) => theme.palette.background.paper + 'F5',
				borderTop: (theme) => `3px double ${theme.palette.primary.main}`,
			}}
			onPoseComplete={(pose) => {
				if (pose === 'fechado') {
					setData(dayjs());
				}
			}}
		>
			{poseState === 'minimizado' && (
				<Box
					display="flex"
					flex="none"
					justifyContent="center"
					onClick={() => setMinimizado(!minimizado)}
				>
					Ponto de Embarque
				</Box>
			)}
			<Box display="flex">
				{!imagens.loading &&
					imagens.ponto === pontoSelecionado &&
					Object.values(imagens.dados).map((image, i) => {
						const tamanhos = image.tamanhos
							? Object.keys(image.tamanhos).sort((a, b) => a - b)
							: [];

						const src = tamanhos.length > 0 ? tamanhos[0] : '';

						const srcSet = tamanhos.reduce((prev, tamanho, i) => {
							return (
								prev +
								image.tamanhos[tamanho] +
								' ' +
								tamanho +
								'w' +
								(i === tamanhos.length - 1 ? '' : ',')
							);
						}, '');

						if (true) {
							return (
								<ImagemPonto
									key={i}
									src={src}
									srcSet={srcSet}
									borderRadius={1}
									sx={{
										flex: 1,
										height: '100%',
										width: '100%',
										objectFit: 'cover',
										borderTopLeftRadius: (theme) =>
											theme.shape.borderRadius * 3,
									}}
								/>
							);
						} else {
							<ImagemPonto2
								width="100%"
								height="100%"
								key={i}
								src={src}
								srcSet={srcSet}
								borderRadius={1}
								onOpen={() => setMinimizado(true)}
								onClose={() => setMinimizado(false)}
							/>;
						}
					})}
				<Box p={1} ml="auto" minWidth="80%">
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						flex="none"
					>
						<Box
							flex={1}
							fontWeight="bold"
							pr={1}
							pl={mostarEstrelaFavorito ? 0 : 1}
						>
							{mostarEstrelaFavorito && (
								<ButtonBase
									centerRipple
									onClick={() => {
										if (pontoSelecionado) {
											setFavorito(pontoSelecionado);
										}
									}}
									sx={{
										p: 1,
										borderRadius: '50%',
										color: isFavorito ? 'primary.main' : 'inherit',
										fontSize: '1.5rem',
									}}
								>
									{isFavorito ? (
										<StarRoundedIcon fontSize="inherit" />
									) : (
										<StarBorderRoundedIcon fontSize="inherit" />
									)}
								</ButtonBase>
							)}

							{pontoSelecionado === 'TERMINAL' ? 'Terminal' : 'Embarque'}
						</Box>

						<Paper
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								position: 'absolute',
								top: '-29px',
								right: 0,
								borderRadius: '10px',
								border: 3,
								borderColor: (theme) => theme.palette.primary.main,
								py: 0.5,
								borderStyle: `double`,
								px: 1,
								mr: 0.5,
							}}
						>
							<SvgIcon
								component={minimizado ? IconTopCircle : IconMinify}
								onClick={() => setMinimizado(!minimizado)}
								sx={{ mr: 1, fontSize: '2rem' }}
							/>

							<Box
								sx={{
									width: '2px',
									height: '20px',
									bgcolor: 'text.primary',
									mr: 1,
									borderRadius: '15px',
								}}
							></Box>

							{linhaSelecionada ? (
								<SvgIcon
									component={IconVoltar}
									onClick={() => {
										setLinhaSelecionada(null);
										setSetorSelecionado(null);
										setProximoHorarioPontoMarker(null);
									}}
									sx={{ mr: 0.5, fontSize: '2rem' }}
								/>
							) : (
								<SvgIcon
									component={IconX}
									onClick={() => {
										history.push('/');
										setMinimizado(false);
									}}
									sx={{ fontSize: '2rem' }}
								/>
							)}
						</Paper>
					</Box>

					<DatePicker
						disablePast
						allowSameDateSelection
						disableCloseOnSelect={false}
						renderInput={({
							inputProps: { value, ...inputProps },
							...params
						}) => {
							return (
								<TextField
									fullWidth
									value={`${value} - ${dayjs(data).format('dddd')}`}
									inputProps={inputProps}
									{...params}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											border: 3,
											borderStyle: `double`,
											borderColor: 'primary.main',
										},
									}}
								/>
							);
						}}
						value={data.toDate()}
						onChange={(dataNova) => {
							setData(dataNova);
						}}
						views={['day']}
						cancelText="Cancelar"
						clearText="Limpar"
						toolbarTitle={null}
					/>
				</Box>
			</Box>

			<Box
				display="flex"
				flexDirection="column"
				flex={1}
				sx={{
					overflow: 'auto',

					scrollBehavior: 'smooth',
				}}
			>
				{linhaSelecionada ? (
					<ListarHorarios setor={setores[setorSelecionado]} />
				) : (
					<Box pt={1}>
						{sortBy(setores, ['linha.nome', 'nome']).map(
							({ linha, proximoHorario, ...setor }, index) => {
								return (
									<Box
										key={linha.id}
										onClick={() => {
											setLinhaSelecionada(linha.id);
											setSetorSelecionado(index);
										}}
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											flex: 'none',
											color: 'primary',
											borderRadius: 1,
											mx: 1.5,
											mb: 1,
											px: 1,
											py: 1,
											pt: 1.1,
											boxShadow: `rgb(0 0 0 / 40%) 0px 2px 8px, rgb(0 0 0 / 30%) 0px 7px 13px -4px, rgb(0 0 0 / 20%) 0px -3px 11px inset`,
											background: (theme) => theme.palette.primary.dark,
											color: (theme) => theme.palette.primary.contrastText,
											borderLeft: `16px solid ${linha.cor}`,
										}}
									>
										<Box flex="1" minWidth="0%">
											<Box
												textAlign="left"
												fontWeight="bold"
												sx={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
												}}
											>
												{linha.nome}
											</Box>
											<Box textAlign="left" fontWeight="bold" fontSize={14}>
												Sentido ➡ {setor.nome === 'S1' ? 'Bairro' : 'Terminal'}
											</Box>
										</Box>
										<Paper
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignSelf: 'stretch',
												textAlign: 'center',
												border: 3,
												borderColor: 'primary.main',
												borderBottom: `none`,
												borderRadius: `10px`,
												borderBottomLeftRadius: `11px`,
												borderBottomRightRadius: `11px`,
												height: `fit-content`,
											}}
										>
											{proximoHorario ? (
												<Box
													fontSize={24}
													fontWeight="bold"
													letterSpacing="1px"
												>
													{proximoHorario}
												</Box>
											) : (
												<Box flex={2}>
													<Box fontSize={18} fontWeight="bold" p={0.5}>
														Sem horário
													</Box>
												</Box>
											)}

											<Box
												bgcolor="primary.main"
												color="primary.contrastText"
												borderRadius={1}
												p={0.5}
												sx={{ whiteSpace: 'nowrap' }}
											>
												+ Horários
											</Box>
										</Paper>
									</Box>
								);
							}
						)}
					</Box>
				)}
			</Box>
		</ContainerPose>
	);
};

export default ModalPonto;
