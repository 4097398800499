import React from 'react';
import { useGlobal } from 'reactn';
import { Layer, Feature } from 'react-mapbox-gl';
import PontoIcon from '../images/icone-ponto-onibus.svg';
import { useRouteMatch, useHistory } from 'react-router-dom';
//import { filtrarPontos } from '../functions/filtros';

const pontoImg = new Image(50, 50);
pontoImg.src = PontoIcon;

const LayerPontos = () => {
	const [pontos] = useGlobal('pontos');
	const [trajetoria] = useGlobal('trajetoria');

	const pontoRoute = useRouteMatch('/ponto/:pontoId');

	const history = useHistory();

	const pontoSelecionado = pontoRoute && pontoRoute.params.pontoId;

	let features = trajetoria
		? trajetoria.pontos.map(id => pontos.dados[id])
		: pontoSelecionado
		? pontoSelecionado.toUpperCase() === 'TERMINAL'
			? []
			: [pontos.dados[pontoSelecionado]]
		: Object.values(pontos.dados);

	return (
		<Layer
			type="symbol"
			id="pontos"
			layout={{
				'icon-image': 'PontoIcon',
				'icon-size': [
					'interpolate',
					['exponential', 1],
					['zoom'],
					0,
					0.1,
					11,
					0.5,
					22,
					0.65
				],
				'symbol-avoid-edges': true,
				'icon-allow-overlap': true
			}}
			images={['PontoIcon', pontoImg]}
			before="terminais"
		>
			{!pontos.loading &&
				features.map(
					(ponto, i) =>
						ponto && (
							<Feature
								key={i}
								onClick={() => history.push(`/ponto/${ponto.id}`)}
								coordinates={[ponto.longitude, ponto.latitude]}
								properties={{
									selecionado: ponto.id === pontoSelecionado
								}}
							/>
						)
				)}
		</Layer>
	);
};

export default LayerPontos;
