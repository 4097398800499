import React, { Component } from 'react';

import { Button, Box, SvgIcon } from '@mui/material';

import { ReactComponent as IconAppOlaOnibus } from '../images/icone-app-ola-onibus.svg';

import ModalTermos from './MostrarTermos';

export default class TermosDeUso extends Component {
	state = {
		aberto: false,
		StateComponent: null,
	};

	constructor(props) {
		super(props);

		let termos_aceitados =
			window.localStorage.getItem('TERMOS_DE_USO') === 'true';

		this.state = {
			show: termos_aceitados ? false : true,
			prosseguir: termos_aceitados ? true : false,
		};
	}

	prosseguir = () => {
		this.setState({
			show: false,
		});
	};

	render() {
		const { StateComponent } = this.state;
		const { show } = this.state;
		if (!show) {
			return null;
		} else {
			return (
				<>
					<Box
						display="flex"
						bgcolor="background.default"
						height="100%"
						position="fixed"
						top="0"
						zIndex="6000"
						width="100%"
					>
						<Box alignSelf="center" textAlign="center" mx={2} width={1}>
							<SvgIcon
								inheritViewBox
								component={IconAppOlaOnibus}
								sx={{ height: 'auto', width: [200, 400] }}
							/>

							<Box p={1}>
								<Box>BETA - Olá Ônibus</Box>
								<Box p={1} textAlign="center">
									<b>Versão disponibilizada para testes</b>
									<br />
									Todas as informações da aplicação foram adquiridas pela nossa
									equipe e através do site da &nbsp;
									<a
										style={{
											textDecoration: 'none',
											fontWeight: '900',
											color: 'inherit',
										}}
										href="http://www.melissatur.com.br/consulta-itinerario"
										rel="noreferrer noopener"
										target="_blank"
									>
										Melissatur (melissatur.com.br)
									</a>
									&nbsp; que é a empresa responsável pelo transporte urbano de
									Campo Mourão.
									<br /> Estamos trabalhando para melhorar as informações e
									acessibilidade do aplicativo.
								</Box>
							</Box>
							<Box mb={3}>
								<input
									type="checkbox"
									id="aceitoTermos"
									nome="subscribe"
									value="concordo"
									onChange={(e) => {
										if (e.target.checked) {
											window.localStorage.setItem('TERMOS_DE_USO', 'true');
											this.setState({ prosseguir: true });
										} else {
											window.localStorage.setItem('TERMOS_DE_USO', 'false');
											this.setState({ prosseguir: false });
										}
									}}
								/>
								<Box component="label" htmlFor="aceitoTermos" ml={1}>
									Concordo com os &nbsp;
								</Box>
								<Box
									component="span"
									color="info.main"
									onClick={() => {
										this.setState({
											StateComponent: ModalTermos,
											aberto: true,
										});
									}}
									sx={{ textDecoration: 'underline' }}
								>
									Termos de Uso e Política de Privacidade
								</Box>
							</Box>

							<Button
								disabled={!this.state.prosseguir}
								variant="contained"
								onClick={() => this.prosseguir()}
								sx={{ width: [200, 400] }}
							>
								Prosseguir
							</Button>
						</Box>
					</Box>
					{StateComponent && (
						<StateComponent
							onClose={() =>
								this.setState({
									StateComponent: null,
								})
							}
						/>
					)}
				</>
			);
		}
	}
}
