import { createTheme, responsiveFontSizes } from '@mui/material';

import { merge } from 'lodash-es';

const theme = {
	typography: {
		fontFamily: 'Mukta, sans-serif',
		fontSize: 16,
	},
	palette: {
		common: { white: '#fbfbfb', black: '#444444' },
		primary: {
			main: '#1857C4',
			gray: '#121212',
		},
		mapbox: {
			primary: `#343332`
		},
		secondary: { main: '#ffcf08' },
	},
	shape: { borderRadius: 8 },
	components: {
		MuiButtonBase: {
			styleOverrides: {
				root: {
					fontFamily: 'Mukta, sans-serif',
					fontSize: 16,
				},
			},
		},
	},
};

export const themeLight = responsiveFontSizes(
	createTheme(
		merge({}, theme, {
			palette: {
				mode: 'light',
				background: {
					default: '#fbfbfb',
					paper: '#fbfbfb',
				},
			},
		})
	)
);

export const themeDark = responsiveFontSizes(
	createTheme(
		merge({}, theme, {
			palette: {
				mode: 'dark',
			},
		})
	)
);
