import React, { useEffect } from 'react';

import { setGlobal, useGlobal } from 'reactn';

import {
	BrowserRouter as Router,
	useRouteMatch,
	Switch,
	Route,
} from 'react-router-dom';

import './configs/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
	doc,
	collection,
	query,
	where,
	onSnapshot,
	getFirestore,
} from 'firebase/firestore';

import Menu from './components/Menu';
import MenuInferior from './components/MenuInferior';
import ModalPonto from './components/ModalPonto';
//import ModalTrajetoria from './components/ModalTrajetoria';
import Mapa from './components/Mapa';

import Login from './components/Login';
import TermosDeUso from './components/TermosDeUso';
import ModalTermos from './components/MostrarTermos';
import DesembarqueNoturno from './components/DesembarqueNoturno';
import ModalFeedBack from './components/FeedBack';
import ModalFavoritos from './components/ModalFavoritos';

import { ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDayjs';

import { themeLight, themeDark } from './theme';

import * as turf from '@turf/turf';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import { useEffectOnce, usePrevious } from 'react-use';

dayjs.locale(ptBR);

const TERMINAL = [-52.37859606742859, -24.04493799300011];

setGlobal({
	menuHeight: 56,

	// maxBounds: [
	// 	[-52.516581574344784, -24.316591607050896],
	// 	[-52.243731099885665, -23.874483393395067],
	// ],

	mapaCentro: TERMINAL,
	zoom: [14],

	localizacao: { longitude: TERMINAL[0], latitude: TERMINAL[1] },

	localizacaoLock: true,

	terminais: {
		loading: true,
		dados: {},
	},

	pontos: { loading: true, dados: {} },
	pontoSelecionado: null,
	pontoSelecionadoLinhas: [],
	proximoHorarioPontoMarker: null,

	linhas: { loading: true, dados: {} },
	linhasSelecionadas: null,
	linhaSelecionada: null,
	imagens: { loading: true, dados: {} },
	imagensTrajetoriaOrigem: { loading: true, dados: {} },
	imagensTrajetoriaDestino: { loading: true, dados: {} },
	horarioLinhaSelecionada: undefined,
	localizacaoMarcadorDestino: null,
	feriados: [],
	offset: 0.55,
	menuDrawerAberto: false,

	auth: null,
	usuario: null,
});

const Effects = () => {
	const [, setGlobalState] = useGlobal();

	const [auth] = useGlobal('auth');

	const [pontoSelecionado, setPontoSelecionado] = useGlobal('pontoSelecionado');
	const [linhaSelecionada] = useGlobal('linhaSelecionada');
	const [terminais] = useGlobal('terminais');
	const [pontos] = useGlobal('pontos');
	const [linhas] = useGlobal('linhas');

	const [localizacaoLock] = useGlobal('localizacaoLock');
	const [localizacao] = useGlobal('localizacao');
	const [mapaCentro] = useGlobal('mapaCentro');

	const [trajetoria] = useGlobal('trajetoria');

	const pontoRoute = useRouteMatch('/ponto/:pontoId');

	// listen auth
	useEffectOnce(() =>
		onAuthStateChanged(getAuth(), (auth) => {
			setGlobalState({ auth });
		})
	);

	// listen localizacao
	useEffectOnce(() => {
		const id = navigator.geolocation.watchPosition(
			({ coords: { latitude, longitude } }) => {
				const localizacaoAtual = turf.point([longitude, latitude]);

				const poly = turf.bboxPolygon([
					-52.458343505859375, -23.95645008134305, -52.29972839355468,
					-24.1705608269577,
				]);

				let localizacao;

				if (turf.booleanPointInPolygon(localizacaoAtual, poly)) {
					localizacao = { latitude, longitude };
				} else {
					localizacao = {
						longitude: TERMINAL[0],
						latitude: TERMINAL[1],
					};
				}

				setGlobalState({ localizacao });
			}
		);

		return () => navigator.geolocation.clearWatch(id);
	});

	// listen terminais
	useEffectOnce(() =>
		onSnapshot(collection(getFirestore(), 'terminais'), (querySnapshot) =>
			setGlobalState({
				terminais: {
					loading: false,
					dados: Object.assign(
						{},
						...querySnapshot.docs.map((snapshot) => ({
							[snapshot.id]: snapshot.data(),
						}))
					),
				},
			})
		)
	);

	// listen pontos
	useEffectOnce(() =>
		onSnapshot(collection(getFirestore(), 'pontos'), (querySnapshot) =>
			setGlobalState({
				pontos: {
					loading: false,
					dados: Object.assign(
						{},
						...querySnapshot.docs.map((snapshot) => ({
							[snapshot.id]: snapshot.data(),
						}))
					),
				},
			})
		)
	);

	// listen linhas
	useEffectOnce(() =>
		onSnapshot(
			query(
				collection(getFirestore(), 'linhas'),
				where('excluido', '==', false),
				where('ativo', '==', true)
			),
			(querySnapshot) =>
				setGlobalState({
					linhas: {
						loading: false,
						dados: Object.assign(
							{},
							...querySnapshot.docs.map((snapshot) => {
								const linha = snapshot.data();
								return {
									[snapshot.id]: { ...linha, rota: JSON.parse(linha.rota) },
								};
							})
						),
					},
				})
		)
	);

	// listen feriados
	useEffectOnce(() =>
		onSnapshot(
			query(
				collection(getFirestore(), 'feriados'),
				where('ano', '==', dayjs().format('YYYY'))
			),
			(querySnapshot) =>
				setGlobalState({
					feriados:
						querySnapshot.docs.length > 0
							? querySnapshot.docs.map((snap) => {
									let feriado = snap.data();
									return {
										...feriado,
										ddmmyyyy: `${feriado.ano}-${feriado.mes}-${feriado.dia}`,
									};
							  })
							: [],
				})
		)
	);

	// listen usuario
	useEffect(() => {
		if (auth) {
			return onSnapshot(doc(getFirestore(), 'usuarios', auth.uid), (snapshot) =>
				setGlobalState({
					usuario: snapshot.data(),
				})
			);
		}
	}, [setGlobalState, auth]);

	// listen imagens
	useEffect(() => {
		if (pontoSelecionado)
			return onSnapshot(
				query(
					collection(getFirestore(), 'imagensPontos'),
					where('ponto', '==', pontoSelecionado)
				),
				(querySnapshot) =>
					setGlobalState({
						imagens: {
							loading: false,
							ponto: pontoSelecionado,
							dados: Object.assign(
								{},
								...querySnapshot.docs.map((snapshot) => ({
									[snapshot.id]: snapshot.data(),
								}))
							),
						},
					})
			);
	}, [pontoSelecionado, setGlobalState]);

	// listen imagens trajetoria
	useEffect(() => {
		if (trajetoria) {
			const origemUnSub = onSnapshot(
				query(
					collection(getFirestore(), 'imagensPontos'),
					where('ponto', '==', trajetoria.pontos[0])
				),
				(querySnapshot) =>
					setGlobalState({
						imagensTrajetoriaOrigem: {
							loading: false,
							ponto: trajetoria.pontos[0],
							dados: Object.assign(
								{},
								...querySnapshot.docs.map((snapshot) => ({
									[snapshot.id]: snapshot.data(),
								}))
							),
						},
					})
			);

			const destinoUnSub = onSnapshot(
				query(
					collection(getFirestore(), 'imagensPontos'),
					where('ponto', '==', trajetoria.pontos[1])
				),
				(querySnapshot) =>
					setGlobalState({
						imagensTrajetoriaDestino: {
							loading: false,
							ponto: trajetoria.pontos[1],
							dados: Object.assign(
								{},
								...querySnapshot.docs.map((snapshot) => ({
									[snapshot.id]: snapshot.data(),
								}))
							),
						},
					})
			);

			return () => {
				origemUnSub();
				destinoUnSub();
			};
		}
	}, [trajetoria, setGlobalState]);

	// localizacao lock
	useEffect(() => {
		if (localizacaoLock && (pontoSelecionado || trajetoria)) {
			setGlobalState({
				localizacaoLock: false,
			});
		} else if (
			localizacaoLock &&
			localizacao &&
			(mapaCentro[0] !== localizacao.longitude ||
				mapaCentro[1] !== localizacao.latitude)
		) {
			setGlobalState({
				mapaCentro: [localizacao.longitude, localizacao.latitude],
				zoom: [14],
			});
		}
	}, [
		pontoSelecionado,
		trajetoria,
		localizacao,
		localizacaoLock,
		mapaCentro,
		setGlobalState,
	]);

	// set view
	const prevPontoSelecionado = usePrevious(pontoSelecionado);
	const prevTrajetoria = usePrevious(trajetoria);

	useEffect(() => {
		if (!pontos.loading && !linhas.loading) {
			const ponto =
				pontoSelecionado === 'TERMINAL'
					? Object.values(terminais.dados)[0]
					: pontos.dados[pontoSelecionado];

			if (trajetoria) {
				const bbox = turf.bbox(trajetoria.features);

				if (bbox.length > 0) {
					setGlobalState({
						fitBounds: [
							[bbox[0], bbox[1]],
							[bbox[2], bbox[3]],
						],
					});
				}
			} else if (ponto) {
				const pontoSelecionadoLinhas = Object.values(linhas.dados)
					.filter((linha) => {
						if (pontoSelecionado === 'TERMINAL') {
							return linha.terminal === 'centro';
						} else {
							return (
								Array.isArray(linha.pontos) &&
								linha.pontos.find((id) => id === pontoSelecionado)
							);
						}
					})
					.map((linha) => linha.id);

				const boxLinhasIds = linhaSelecionada
					? [linhaSelecionada]
					: pontoSelecionadoLinhas;

				const boxLinhas = boxLinhasIds.map((id) => linhas.dados[id]);

				const bboxArray = boxLinhas.map((linha) => {
					return turf.bbox(
						turf.lineString(
							linha.rota.coordinates.map((ca) =>
								ca.map((c) => (c.toFixed ? c.toFixed(6) : c))
							)
						)
					);
				});

				const bbox = bboxArray.reduce((acc, box) => {
					box.forEach((coord, i) => {
						if (i < 2) {
							if (!acc[i] || coord < acc[i]) {
								acc[i] = coord;
							}
						} else {
							if (!acc[i] || coord > acc[i]) {
								acc[i] = coord;
							}
						}
					});
					return acc;
				}, []);

				if (bbox.length > 0) {
					setGlobalState({
						fitBounds: [
							[bbox[0], bbox[1]],
							[bbox[2], bbox[3]],
						],
						pontoSelecionadoLinhas,
					});
				} else {
					setGlobalState({
						mapaCentro: [ponto.longitude, ponto.latitude],
						fitBounds: null,
					});
				}
			} else if (!trajetoria && prevTrajetoria) {
				setGlobalState({
					localizacaoLock: true,
					fitBounds: null,
					mapaCentro: [localizacao.longitude, localizacao.latitude],
					zoom: [14],
				});
			} else if (pontoSelecionado !== prevPontoSelecionado) {
				const prevPonto = prevPontoSelecionado
					? prevPontoSelecionado === 'TERMINAL'
						? Object.values(terminais.dados)[0]
						: pontos.dados[prevPontoSelecionado]
					: null;

				const update = {
					zoom: [14],
					offset: 0.55,
					fitBounds: null,
					pontoSelecionadoLinhas: [],
				};
				if (prevPonto) {
					update.mapaCentro = [prevPonto.longitude, prevPonto.latitude];
				}

				setGlobalState(update);
			}
		}
	}, [
		localizacao,
		linhas,
		pontos,
		pontoSelecionado,
		prevPontoSelecionado,
		linhaSelecionada,
		terminais,
		trajetoria,
		prevTrajetoria,
		setGlobalState,
	]);

	// set ponto selecionado com base rota
	useEffect(() => {
		const novoPontoSelecionado = pontoRoute ? pontoRoute.params.pontoId : null;

		if (novoPontoSelecionado !== pontoSelecionado) {
			setPontoSelecionado(novoPontoSelecionado);
		}
	}, [pontoRoute, pontoSelecionado, setPontoSelecionado]);

	return null;
};

const App = () => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
		noSsr: true,
	});

	return (
		<Router>
			<LocalizationProvider dateAdapter={DateAdapter} locale={ptBR}>
				<ThemeProvider theme={prefersDarkMode ? themeDark : themeLight}>
					<CssBaseline />

					<Effects />

					<Menu />
					<MenuInferior />

					<Mapa dark={prefersDarkMode} />

					<ModalPonto />

					{/* <ModalTrajetoria /> */}

					<TermosDeUso />

					<Switch>
						<Route exact path="/Login">
							<Login />
						</Route>
						<Route exact path="/Favoritos">
							<ModalFavoritos />
						</Route>
						<Route exact path="/TermosDeUso">
							<ModalTermos />
						</Route>
						<Route exact path="/Feedback">
							<ModalFeedBack />
						</Route>
						<Route exact path="/DesembarqueNoturno">
							<DesembarqueNoturno />
						</Route>
					</Switch>
				</ThemeProvider>
			</LocalizationProvider>
		</Router>
	);
};

export default App;
