import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import posed from 'react-pose';

const PoseBox = posed(Box)({
    boxAberto: {
        applyAtStart: {
            position: 'fixed',
            width: '0px',
            height: '0px',
        },
        width: '100%',
        height: '100%',
        left: `0%`,
        applyAtEnd: {

        }
    },
    boxFechado: {
        applyAtStart: { 
            bottom: `0%`,
            left: `0%`,
        },
        width: ({ inicial }) =>  inicial.width ? (inicial.width + 'px') : '17%',
        height: ({ inicial }) =>  inicial.height ? (inicial.height + 'px') : '17%',
        bottom: ({ inicial }) =>  inicial.bottom ? (inicial.bottom + 'px') : '40%',
        left: ({ inicial }) =>  inicial.left ? (inicial.left + 'px') : '0%',
        applyAtEnd: {
            position: `inherit`,
            width: ({ inicial }) =>  inicial.width ? (inicial.width + 'px') : '100%',
            height: ({ inicial }) =>  inicial.height ? (inicial.height + 'px') : '100%',
            bottom: `0%`,
        }
    },
});

const ImagemPonto2 = (props) => {

    const ref = useRef();

    const [aberto, setAberto] = useState(false);

    const [loading, setLoading] = useState(true);

    const [countClick, setCountClick] = useState(0);

    const [inicial, setInicial] = useState({});

    function updateInicial() {
		if (
			ref.current && countClick == 0
		) {
            setCountClick(countClick + 1);
			const bounding = ref.current.getBoundingClientRect();

            console.log({
				naturalHeight: ref.current.naturalHeight,
				naturalWidth: ref.current.naturalWidth,
				height: bounding.height,
				width: bounding.width,
				top: bounding.top,
				bottom: bounding.bottom,
				left: bounding.left,
				right: bounding.right,
			})

			setInicial({
				naturalHeight: ref.current.naturalHeight,
				naturalWidth: ref.current.naturalWidth,
				height: bounding.height,
				width: bounding.width,
				top: bounding.top,
				bottom: bounding.bottom,
				left: bounding.left,
				right: bounding.right,
			});
		}
	}


    return (
        <PoseBox
            pose={aberto ? 'boxAberto' : 'boxFechado'}
            className="width-transition-photo"
            sx={{
                display: 'flex',
                flexDirection: `column`,
                justifyContent: `space-evenly`,
                zIndex: 1,
                alignItems: `center`,
                background: theme => aberto ? theme.palette.mapbox.primary + `f5` : `transparent`,
            }}
            inicial={inicial}
        >
            {aberto && <Box sx={{fontSize: 25, fontWeight: `bold`}}>Ponto de Embarque</Box>}

            {loading && <div className="load-3">
                <div className="circle-loading"></div>
                <div className="circle-loading"></div>
                <div className="circle-loading"></div>
            </div>}
      
            <img
                ref={ref}
                alt="Ponto de Embarque"
                onClick={(e) => {
                    if (typeof props.onClick === 'function') {
                        props.onClick(e);
                    }

                    if (!aberto) {
						updateInicial();
					}

                    try {
                        aberto ? props?.onClose() : props?.onOpen();
                    } catch(err) {
                        console.warn(err);
                    }
                    setAberto(!aberto);
                }}
                style={{
                    zIndex: 2,
                    width: aberto ? '100%' : props.width,
                    height: aberto ? '70%' : props.height,
                    objectFit: `cover`,
                    borderRadius: `14px`,
                    display: loading ? `none` : `inherit`,
                }}
                onLoad={() => {
                    setLoading(false)
                }}
                src={props.src}
                srcSet={props.srcSet}
            />
            {aberto && <span sx={{fontSize: 25, fontWeight: `bold`}} >Toque para fechar</span>}

        </PoseBox>
    );
};

export default ImagemPonto2;
