import React from 'react';
import { useGlobal } from 'reactn';

import { Redirect, Link } from 'react-router-dom';

import { List, ListItem, ListItemText, Button, Box } from '@mui/material';

import { round } from 'lodash-es';

const Login = () => {
	const [auth] = useGlobal('auth');
	const [usuario] = useGlobal('usuario');
	const [pontos] = useGlobal('pontos');
	const [, setMenuDrawerAberto] = useGlobal('menuDrawerAberto');

	return auth ? (
		<Box
			bgcolor="background.default"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			p={0}
			height="100%"
			width="100%"
			position="fixed"
			top="0"
			zIndex="6000"
			textAlign="justify"
		>
			<List sx={{ flex: 1 }}>
				{usuario &&
					Array.isArray(usuario.pontosFavoritos) &&
					usuario.pontosFavoritos.map(
						(favoritoId) =>
							pontos.dados[favoritoId] && (
								<ListItem
									button
									divider
									key={favoritoId}
									component={Link}
									to={`/ponto/${favoritoId}`}
									onClick={() => setMenuDrawerAberto(false)}
								>
									<ListItemText
										primary={pontos.dados[favoritoId].bairro}
										secondary={`${round(
											pontos.dados[favoritoId].latitude,
											6
										)}, ${round(pontos.dados[favoritoId].longitude, 6)}`}
									/>
								</ListItem>
							)
					)}
			</List>

			<Button
				variant="contained"
				component={Link}
				to="/"
				sx={{ m: 1, flex: 'none', mt: 'auto', alignSelf: 'center' }}
			>
				Fechar
			</Button>
		</Box>
	) : (
		<Redirect to="/Login" />
	);
};

export default Login;
