import React from 'react';
import { useGlobal } from 'reactn';

import {
	getAuth,
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithPopup,
} from 'firebase/auth';

import { Redirect, Link } from 'react-router-dom';

import IconFacebookWhite from '../images/icone-facebook-white.svg';
import IconGoogle from '../images/icone-google.svg';

import { Button, ButtonBase, Box } from '@mui/material';

const Login = () => {
	const [auth] = useGlobal('auth');

	return auth ? (
		<Redirect to="/" />
	) : (
		<Box
			bgcolor="background.default"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			py={0}
			px={2.5}
			height="100%"
			width="100%"
			position="fixed"
			top="0"
			zIndex="6000"
			textAlign="justify"
		>
			<Box
				flex={1}
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				{[
					{
						name: 'Google',
						image: IconGoogle,
						colorBackground: '#FFF',
						colorFont: '#000',
						provider: new GoogleAuthProvider(),
					},
					{
						name: 'Facebook',
						image: IconFacebookWhite,
						colorBackground: '#4267b2',
						colorFont: '#FFF',
						provider: new FacebookAuthProvider(),
					},
				].map(({ name, image, colorBackground, colorFont, provider }) => (
					<ButtonBase
						key={name}
						onClick={() => {
							signInWithPopup(getAuth(), provider).catch((error) => {
								console.error(error);
							});
						}}
						sx={{
							p: 2,
							fontFamily: 'fontFamily',
							fontSize: 'button.fontSize',
							boxShadow: 2,
							m: 1,
							borderRadius: 2.5,
							width: 250,
							bgcolor: colorBackground,
							color: colorFont,
						}}
					>
						<Box component="img" height="25px" mr={1} src={image} alt={name} />
						Entrar com {name}
					</ButtonBase>
				))}
			</Box>

			<Button
				variant="contained"
				component={Link}
				to="/"
				sx={{ m: 1, flex: 'none', mt: 'auto' }}
			>
				Fechar
			</Button>
		</Box>
	);
};

export default Login;
