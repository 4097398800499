import React from 'react';
import { useGlobal } from 'reactn';
import { Marker } from 'react-mapbox-gl';
import { Box } from '@mui/material';

const MarkerProximoHorarioPonto = () => {
	const [pontos] = useGlobal(`pontos`);
	const [proximoHorarioPontoMarker] = useGlobal(`proximoHorarioPontoMarker`);

	let ponto = null;
	if (proximoHorarioPontoMarker && proximoHorarioPontoMarker.pontoId) {
		ponto = pontos.dados[proximoHorarioPontoMarker.pontoId];
	}

	if (!ponto || !proximoHorarioPontoMarker) {
		return null;
	}

	return (
		<Marker
			coordinates={[ponto.longitude, ponto.latitude]}
			anchor="bottom"
			width="300px"
		>
			<Box
				sx={{
					borderRadius: 0.5,
					position: 'relative',
					bgcolor: 'background.paper',
					color: 'text.primary',
					p: 1,
					top: '-20px',
					'&:after': {
						zIndex: -1,
						position: 'absolute',
						top: ' 99.1%',
						left: '57%',
						marginLeft: '-25%',
						content: '""',
						width: 0,
						height: 0,
						borderTop: (theme) =>
							`solid 12px ${theme.palette.background.paper}`,
						borderLeft: 'solid 10px transparent',
						borderRight: 'solid 10px transparent',
					},
				}}
			>
				<Box
					textAlign="center"
					fontSize={16}
					sx={{
						//color: 'black',
						fontWeight: 'bold',
					}}
				>
					{proximoHorarioPontoMarker.horario}
				</Box>
			</Box>
		</Marker>
	);
};

export default MarkerProximoHorarioPonto;
