const dayjs = require('dayjs');

export const isDayWeek = () => {
	let nameDayOfWeek = dayjs().format('dddd');
	return nameDayOfWeek !== 'Saturday' || nameDayOfWeek !== 'Sunday';
};

export const getDayName = () => dayjs().format('dddd');

export const isSunday = () => dayjs().format('dddd') === 'Sunday';
export const isSaturday = () => dayjs().format('dddd') === 'Saturday';

export const jaPassou = (_horaBus, data) => {
	_horaBus = _horaBus.split(':');
	const horaBus = dayjs(data)
		.set('hour', _horaBus[0])
		.set('minute', _horaBus[1])
		.set('second', '0');
	const diff = horaBus.diff(dayjs(), 'minute');
	return diff <= -8 ? true : false;
};

export const daysByBinary = (value) => {
	let splitedBinary = ((255 - value) >>> 0).toString(2).split('').reverse();
	let dias = [
		{ value: 1, active: true }, //0 - domingo
		{ value: 2, active: true }, //1 - segunda
		{ value: 4, active: true }, //2 - terça
		{ value: 8, active: true }, //3 - quarta
		{ value: 16, active: true }, //4 - quinta
		{ value: 32, active: true }, //5 - sexta
		{ value: 64, active: true }, //6 - sábado
		{ value: 128, active: true }, //7 - feriado
	];

	return dias.map((dia, i) => ({
		...dia,
		active: !parseInt(splitedBinary[i]),
	}));
};
