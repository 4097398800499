import React from 'react';
import { useGlobal } from 'reactn';
import { Layer, Feature } from 'react-mapbox-gl';
import IconPositionUser from '../images/icon-position-user.svg';

const positionImg = new Image(50, 50);
positionImg.src = IconPositionUser;

const LayerPosicaoUsuario = () => {
	const [localizacao] = useGlobal('localizacao');

	return (
		localizacao && (
			// <Layer
			// 	type="circle"
			// 	id="user_position"
			// 	paint={{
			// 		'circle-radius': 5,
			// 		'circle-color': '#FFFFFF'
			// 	}}
			// >
			// 	<Feature coordinates={[localizacao.longitude, localizacao.latitude]} />
			// </Layer>

			<Layer
				type="symbol"
				id="layerPosicaoUsuario"
				layout={{
					'icon-image': 'IconPositionUser',
					'icon-size': [
						'interpolate',
						['exponential', 1],
						['zoom'],
						0,
						0.1,
						11,
						0.5,
						22,
						0.65
					],
					'symbol-avoid-edges': true,
					'icon-allow-overlap': true
				}}
				images={['IconPositionUser', positionImg]}
				before="terminais"
			>
				<Feature coordinates={[localizacao.longitude, localizacao.latitude]} />
			</Layer>
		)
	);
};

export default LayerPosicaoUsuario;
