import React from 'react';
import { useGlobal } from 'reactn';
import { Layer, Feature } from 'react-mapbox-gl';
import MarcadorGpsIcon from '../images/marcador_gps.png';

const marcadorimg = new Image(512, 512);
marcadorimg.src = MarcadorGpsIcon;
marcadorimg.width = 1000;
marcadorimg.height = 1000;

const LayerMarcadorDestino = () => {
	const [trajetoria] = useGlobal('trajetoria');

	return (
		<Layer
			//before="pontos"
			type="symbol"
			id="marcador_gps"
			layout={{
				'icon-image': 'MarcadorIcon',
				'icon-size': [
					'interpolate',
					['exponential', 1],
					['zoom'],
					0,
					0.001,
					11,
					0.035,
					22,
					0.045
				]
			}}
			images={['MarcadorIcon', marcadorimg]}
		>
			{trajetoria && <Feature coordinates={trajetoria.destino} />}
		</Layer>
	);
};

export default LayerMarcadorDestino;
