import React from 'react';

import { Link } from 'react-router-dom';

import { Button, Box, Typography, Link as A } from '@mui/material';

const DesembarqueNoturno = ({ ...props }) => (
	<Box
		bgcolor="background.default"
		display="flex"
		flexDirection="column"
		alignItems="center"
		p={0}
		height="100%"
		width="100%"
		position="fixed"
		top="0"
		zIndex="6000"
		textAlign="justify"
		{...props}
	>
		<Box flex={1} overflow="auto" pt={1} px={2.5}>
			<A
				underline="always"
				href="https://leismunicipais.com.br/a/pr/c/campo-mourao/lei-ordinaria/2012/290/2891/lei-ordinaria-n-2891-2012-estabelece-norma-para-desembarque-de-pessoas-do-sexo-feminino-no-periodo-noturno-no-transporte-coletivo-urbano-no-municipio-de-campo-mourao?q=2891"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Typography variant="h4" component="div">
					Lei Ordinária 2891/2012
				</Typography>
				<Typography variant="h4" component="div" gutterBottom>
					Campo Mourão
				</Typography>
			</A>

			<Typography variant="body1" paragraph>
				NORMA PARA DESEMBARQUE DE PESSOAS DO SEXO FEMININO, NO PERÍODO NOTURNO,
				NO TRANSPORTE COLETIVO URBANO NO MUNICÍPIO DE CAMPO MOURÃO.
			</Typography>

			<Typography variant="h6">Artigo 1</Typography>
			<Typography variant="body2" paragraph>
				Estabelece norma para desembarque de pessoas do sexo feminino, no
				período noturno, no transporte coletivo urbano no Município de Campo
				Mourão.
			</Typography>
			<Typography variant="h6">Artigo 2</Typography>
			<Typography variant="body2" paragraph>
				Os condutores dos veículos utilizados para a prestação de transporte
				coletivo urbano, após as 22 (vinte e duas) horas, deverão parar os
				ônibus para possibilitar o desembarque de pessoas do sexo feminino em
				qualquer local onde seja permitido estacionamento, no trajeto regular da
				respectiva linha, mesmo que nele não haja ponto de parada regulamentado.
			</Typography>
			<Typography variant="h6" component="span">
				Artigo 3
			</Typography>
			<Typography variant="body2" paragraph>
				Esta Lei entra em vigor na data de sua publicação.
			</Typography>
		</Box>

		<Button
			variant="contained"
			component={Link}
			to="/"
			sx={{ flex: 'none', m: 2 }}
		>
			Fechar
		</Button>
	</Box>
);

export default DesembarqueNoturno;
