import React, { useState, useRef } from 'react';
import { useGlobal } from 'reactn';
import { useInterval, useEffectOnce } from 'react-use';
import { getAuth, signOut } from 'firebase/auth';

import { Link } from 'react-router-dom';

import { ReactComponent as IconAppOlaOnibus } from '../images/icone-app-ola-onibus.svg';
import { ReactComponent as IconConta } from '../images/account.svg';
//import IconPagamento from '../images/cash.svg';
import { ReactComponent as IconFeedback } from '../images/feedback.svg';
//import IconSaldo from '../images/saldo.svg';
import { ReactComponent as IconTermos } from '../images/term.svg';
import { ReactComponent as IconStar } from '../images/stars-24px.svg';
import { ReactComponent as IconMenu } from '../images/icone-menu.svg';
import { ReactComponent as IconSearch } from '../images/search.svg';
import { ReactComponent as IconX } from '../images/x-circle.svg';
import { ReactComponent as IconFacebook } from '../images/facebook.svg';
import { ReactComponent as IconInstagram } from '../images/instagram.svg';

import {
	ButtonBase,
	Typography,
	SwipeableDrawer,
	Collapse,
	Link as A,
	Box,
	SvgIcon,
} from '@mui/material';

import HoverBox from './HoverBox';

import DestinoSearch from './DestinoSearch';
import dayjs from 'dayjs';

// Deveria trocar o SwipableDrawer do materil-ui por uma implementação propria. O SwipableDrawer do material-ui coloca um aria-label="hidden" no root quando o menu estiver aberto

const Menu = () => {
	const ref = useRef(null);

	const [menuHeight, setMenuHeight] = useGlobal('menuHeight');
	const [drawerAberto, setDrawerAberto] = useGlobal('menuDrawerAberto');
	const [pontoSelecionado] = useGlobal('pontoSelecionado');
	const [trajetoria] = useGlobal('trajetoria');
	const [auth] = useGlobal('auth');

	const [desembarqueNoturnoHora, setDesembarqueNoturnoHora] = useState(false);
	const [destinoSearchAberto, setDestinoSearchAberto] = useState(false);

	function desembarqueNoturno() {
		const hora = dayjs().hour();

		if (hora >= 22 || hora < 5) {
			if (!desembarqueNoturnoHora) setDesembarqueNoturnoHora(true);
		} else {
			if (desembarqueNoturnoHora) setDesembarqueNoturnoHora(false);
		}
	}

	useEffectOnce(desembarqueNoturno);
	useInterval(desembarqueNoturno, 60000);

	const desembarqueNoturnoVisivel =
		desembarqueNoturnoHora && !(pontoSelecionado || trajetoria);

	function setMenu() {
		if (
			ref.current &&
			typeof setMenuHeight === 'function' &&
			ref.current.offsetHeight !== menuHeight
		) {
			setMenuHeight(ref.current.offsetHeight);
		}
	}

	return (
		<>
			<Box
				component={Collapse}
				in={!pontoSelecionado}
				onEntered={setMenu}
				onExited={setMenu}
				ref={(r) => {
					ref.current = r;
					setMenu();
				}}
				position="fixed"
				top="0"
				left="0"
				right="0"
				zIndex="1"
				m={1}
			>
				<Box display="flex" alignItems="center">
					<ButtonBase
						onClick={() => setDrawerAberto(true)}
						sx={{
							display: 'flex',
							bgcolor: 'background.paper',
							borderRadius: 6,
							boxShadow: 12,
						}}
					>
						<HoverBox
							flex={1}
							display="flex"
							justifyContent="center"
							alignItems="center"
							px={2}
							py={1}
							borderRadius="inherit"
						>
							<IconMenu height="1.5rem" width="1.5rem" />
						</HoverBox>
					</ButtonBase>

					{/* <ButtonBase
						onClick={() => setDestinoSearchAberto(true)}
						sx={{
							display: 'flex',
							flex: '1',
							bgcolor: 'background.paper',
							borderRadius: 6,
							ml: 1,
							boxShadow: 12,
						}}
					>
						<HoverBox
							display="flex"
							flex={1}
							alignItems="center"
							borderRadius="inherit"
						>
							<SvgIcon
								inheritViewBox
								component={IconSearch}
								sx={{ m: 1, height: '1.5rem', width: 'auto' }}
							/>

							<Typography variant="h6" component="div" sx={{ m: 0.5, flex: 1 }}>
								Para onde?
							</Typography>
						</HoverBox>
					</ButtonBase> */}
				</Box>

				<Collapse
					unmountOnExit
					in={desembarqueNoturnoVisivel}
					onEntered={setMenu}
					onExited={setMenu}
				>
					<Box
						mt={1.5}
						p={1.5}
						pb={0.5}
						borderRadius={1}
						bgcolor="background.default"
						color="text.primary"
						boxShadow={12}
						position="relative"
						border={5}
						borderTop={0}
						borderLeft={0}
						borderRight={0}
						textAlign="justify"
						overflow="auto"
						display="block"
						component={Link}
						to="/DesembarqueNoturno"
						sx={{ textDecoration: 'none', borderColor: 'primary.main' }}
					>
						Permitido desembarque de mulheres fora do ponto após as 22h.
						<A
							underline="always"
							component="div"
							sx={{
								display: 'inline-block',
								fontSize: 'h6.fontSize',
								fontWeight: 'h6.fontWeight',
								float: 'right',
							}}
						>
							Saiba Mais
						</A>
					</Box>
				</Collapse>
			</Box>

			<DestinoSearch
				aberto={destinoSearchAberto}
				aoFechar={() => setDestinoSearchAberto(false)}
			/>

			<SwipeableDrawer
				disableDiscovery
				open={drawerAberto}
				onClose={() => setDrawerAberto(false)}
				onOpen={() => setDrawerAberto(true)}
				SlideProps={{
					unmountOnExit: false,
					// tabindex="none" permite que campos de texto do material-ui funcionem enquanto o menu estiver aberto.
					tabIndex: 'none',
				}}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					'& .MuiDrawer-paper': {
						mr: 1,
						pl: 1,

						right: '0',

						maxWidth: '32rem',

						borderRadius: 6,

						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					},
				}}
			>
				<Box display="flex" p={1}>
					<SvgIcon
						inheritViewBox
						component={IconAppOlaOnibus}
						sx={{ my: 1, height: '1.8rem', width: 'auto' }}
					/>

					<ButtonBase
						onClick={() => setDrawerAberto(false)}
						sx={{
							display: 'flex',
							p: 0,
							ml: 'auto',
							px: 2,
							py: 1,
							border: 'none',
							borderRadius: '100px',
						}}
					>
						<SvgIcon component={IconX} sx={{ mr: 0.5, fontSize: '2rem' }} />
					</ButtonBase>
				</Box>

				{auth ? (
					<ButtonBase
						onClick={() => {
							signOut(getAuth()).catch(function (error) {
								console.log(error);
								// An error happened.
							});
						}}
						sx={{
							p: 1,
							borderTop: 1,
							borderBottom: 1,
							borderColor: 'common.black',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'initial',
							textAlign: 'initial',
							color: 'inherit',
						}}
					>
						<Box
							component="img"
							src={auth.photoURL}
							alt={auth.displayName}
							sx={{
								borderRadius: '100%',
								width: '65px',
								height: '65px',
								objectFit: 'cover',
							}}
						/>

						<Box ml={1}>
							<Box fontSize="subtitle1.fontSize">{auth.displayName}</Box>
							<Box fontSize="caption.fontSize" display="block">
								{auth.email}
							</Box>

							<Box fontSize="button.fontSize" fontWeight="button.fontWeight">
								Sair
							</Box>
						</Box>
					</ButtonBase>
				) : (
					<Box
						component={Link}
						to="/Login"
						sx={{
							p: 1,
							borderTop: 1,
							borderBottom: 1,
							borderColor: 'common.black',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'initial',
							textAlign: 'initial',
							color: 'inherit',
						}}
					>
						<Box display="flex" alignItems="center">
							<SvgIcon
								inheritViewBox
								component={IconConta}
								sx={{ py: 1.5, height: '65px', width: '65px' }}
							/>

							<Box fontSize="h5.fontSize" fontWeight="button.fontWeight" ml={1}>
								Login
							</Box>
						</Box>
					</Box>
				)}

				<nav>
					{[
						{
							name: 'Favoritos',
							path: '/Favoritos',
							image: IconStar,
						},
						{
							name: 'FeedBack',
							path: '/Feedback',
							image: IconFeedback,
						},
						{
							name: 'Termos de Uso',
							path: '/TermosDeUso',
							image: IconTermos,
						},

						{
							name: 'Lei do Desembarque Noturno',
							path: '/DesembarqueNoturno',
							image: IconTermos,
						},
					].map(({ name, embreve, image, path }) => (
						<Box
							key={name}
							fontSize="h5.fontSize"
							p={2}
							color={embreve ? 'text.disabled' : 'text.primary'}
							display="flex"
							alignItems="center"
							component={path ? Link : undefined}
							to={path}
							sx={{
								textDecoration: 'none',
								//'-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)'
							}}
						>
							<SvgIcon
								inheritViewBox
								component={image}
								sx={{ mr: 1, height: 'auto', width: '1.75rem' }}
							/>

							{name}
							{embreve && (
								<Box
									bgcolor="secondary.main"
									color="secondary.contrastText"
									p={0.75}
									fontSize="caption.fontSize"
									borderRadius={1}
									ml={1}
								>
									Em breve
								</Box>
							)}
						</Box>
					))}
				</nav>
				<Box
					position="absolute"
					bottom="0"
					left="0"
					display="flex"
					justifyContent="flex-end"
					width="100%"
					height="2.5rem"
					paddingBottom="1rem"
					paddingRight="2rem"
				>
					{[
						{
							name: 'Facebook',
							image: IconFacebook,
							link: 'http://www.facebook.com/olaonibus',
						},
						{
							name: 'Instagram',
							image: IconInstagram,
							link: 'https://www.instagram.com/olaonibus/',
						},
					].map(({ name, image, link }) => (
						<A
							href={link}
							title={`Visitar o ${name}`}
							key={name}
							rel="noopener noreferrer"
							target="_blank"
							color="inherit"
						>
							<SvgIcon
								inheritViewBox
								component={image}
								sx={{ ml: 1, height: 'auto', width: '2rem' }}
							/>
						</A>
					))}
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default Menu;
