import React from 'react';

import { Box, Paper } from '@mui/material';

export default function ListarHorarios({ setor }) {
	const horariosOrdenados =
		setor.horarios.filter((h) => h.jaPassou).length > setor.horarios.length / 2
			? setor.horarios.reverse()
			: setor.horarios;

	return (
		<Box
			flex="1"
			display="flex"
			flexDirection="column"
			width="100%"
			overflow="auto"
		>
			<Box display="flex" width="100%" justifyContent="center">
				<Box textAlign="left" fontWeight="bold" fontSize={20}>
					Sentido ➡ {setor.nome === 'S1' ? 'Bairro' : 'Terminal'}
				</Box>
			</Box>

			<Box display="flex" flexWrap="wrap" p={1} textAlign="center">
				{horariosOrdenados?.length > 0 ? (
					horariosOrdenados.map(({ horario, jaPassou }) => {
						return (
							<Box key={horario} flex="1" p={0.5} minWidth="20%">
								<Paper
									elevation={8}
									sx={{
										flex: '1',
										display: 'flex',
										flexDirection: 'column',
										height: 'fit-content',
										p: 0.5,
										fontWeight: 'bold',
										borderRadius: 1,
										textAlign: 'center',
									}}
								>
									<Box
										fontSize={22}
										fontWeight={jaPassou ? 'initial' : 'bold'}
										color={jaPassou ? 'text.disabled' : 'text.primary'}
									>
										{horario}
									</Box>
									<Box
										sx={{
											width: '100%',
											height: '8px',
											bgcolor: jaPassou ? 'text.disabled' : 'primary.main',
											borderRadius: 0.2,
										}}
									></Box>
								</Paper>
							</Box>
						);
					})
				) : (
					<Box fontSize={[20, 30]} fontWeight="bold" m="auto">
						Nenhum horário disponível hoje
					</Box>
				)}
				{Array.from(Array(5).keys()).map((k) => (
					<Box key={k} flex="1" minWidth="20%" />
				))}
			</Box>
		</Box>
	);
}
