import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';

import { useDebounce, useSetState } from 'react-use';

import { Box, InputBase, Paper, SvgIcon } from '@mui/material';

import { ReactComponent as IconX } from '../images/x-circle.svg';
//import IconSwap from '../images/baseline-swap_vertical_circle-24px.svg';
import { ReactComponent as IconInicoFim } from '../images/inicoFim.svg';

import posed from 'react-pose';

import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

import * as turf from '@turf/turf';

import { uniq } from 'lodash-es';

const geocodingClient = mbxGeocoding({
	accessToken:
		'pk.eyJ1IjoiYi1idW5oYWsiLCJhIjoiY2psNjExM3o1MG40ZzNsbnRvY3cyY2JtciJ9.NtNszpibCfg1fUAPrS33Sg',
});

const SearchContainer = posed(Box)({
	aberto: { y: '0', transition: 'tween' },
	fechado: { y: '-100%', transition: 'tween' },
});

const ResultContainer = posed(Paper)({
	aberto: { y: '0', transition: 'tween' },
	fechado: { y: '-100%', transition: 'tween' },
});

const SearchInput = ({ label, placeholder, value, onChange, sx, ...props }) => (
	<Box
		component="label"
		elevation={16}
		sx={{
			my: 1,
			borderRadius: 1,
			p: 1,
			display: 'block',
			boxShadow: 1,
			border: 'none',
			color: 'inherit',
			...sx,
		}}
		{...props}
	>
		<Box width="15%" fontSize={14} fontWeight="lighter" alignSelf="center">
			{label}
		</Box>
		<InputBase
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			sx={{
				width: '100%',
				bgcolor: 'inherit',
				color: 'inherit',
				flex: 1,
				mx: 0,
				p: 0,
				py: 0.5,
				textAlign: 'left',
				fontSize: 16,
				border: 'none',
				alignSelf: 'center',
				fontWeight: 'bold',
				'::placeholder': { opacity: '0.85' },
				'& > input': { p: 0 },
			}}
		/>
	</Box>
);

const DestinoSearch = ({ aberto, aoFechar }) => {
	const [maxBounds] = useGlobal('maxBounds');

	const [localizacao] = useGlobal('localizacao');

	const [pontos] = useGlobal('pontos');
	const [linhas] = useGlobal('linhas');
	const [terminais] = useGlobal('terminais');

	const [inputs, setInputs] = useSetState({ origem: '', destino: '' });

	const [places, setPlaces] = useSetState({ origem: null, destino: null });

	const [placeSearch, setPlaceSearch] = useState([]);

	const [changeOrigem, setChangeOrigem] = useState(false);

	const [, setTrajetoria] = useGlobal('trajetoria');

	const handleChangeFunc = (nome) => (event) => {
		if (nome === 'origem') {
			setChangeOrigem(true);
		}

		setPlaces({ [nome]: null });
		setInputs({ [nome]: event.target.value });
	};

	function search(input) {
		const sendSearch = (proximity) => {
			return geocodingClient
				.forwardGeocode({
					query: input,
					bbox: maxBounds.flat(),
					proximity,
				})
				.send()
				.then((response) => {
					const match = response.body;

					setPlaceSearch(match.features);
				});
		};

		sendSearch([localizacao.longitude, localizacao.latitude]);
	}

	useDebounce(
		() => {
			if (inputs.destino && !places.destino) {
				search(inputs.destino);
			}
		},
		500,
		[inputs.destino]
	);

	useEffect(() => {
		// abrindo
		if (aberto && !changeOrigem) {
			if (localizacao) {
				geocodingClient
					.reverseGeocode({
						query: [localizacao.longitude, localizacao.latitude],
						limit: 1,
					})
					.send()
					.then((response) => {
						const match = response.body;

						setPlaces({ origem: match.features[0] });

						setInputs({ origem: match.features[0].text || '' });
					});
			}
		}

		// fechando
		if (!aberto) {
			setPlaces({ origem: null, destino: null });
			setInputs({ origem: '', destino: '' });
			setChangeOrigem(false);
		}
	}, [localizacao, aberto, changeOrigem, setInputs, setPlaces]);

	useEffect(() => {
		if (places.origem && places.destino) {
			if (
				!pontos.loading &&
				Object.keys(pontos.dados).length > 0 &&
				!linhas.loading &&
				Object.keys(linhas.dados).length > 0 &&
				!terminais.loading &&
				Object.keys(terminais.dados).length > 0
			) {
				function linhasEmPonto(pontoId) {
					return Object.values(linhas.dados)
						.filter(
							(linha) =>
								Array.isArray(linha.pontos) &&
								linha.pontos.find((id) => id === pontoId)
						)
						.map((linha) => linha.id);
				}

				const pontosFeatures = turf.featureCollection(
					Object.values(pontos.dados).map((p) =>
						turf.point([p.longitude, p.latitude], { id: p.id })
					)
				);

				const origem = turf.point(places.origem.center);

				const pontoInicio = turf.nearestPoint(origem, pontosFeatures);

				const destino = turf.point(places.destino.center);

				const pontoFim = turf.nearestPoint(destino, pontosFeatures);

				const linhasPontoInicio = linhasEmPonto(pontoInicio.properties.id);

				const linhasPontoFim = linhasEmPonto(pontoFim.properties.id);

				setTrajetoria({
					features: turf.featureCollection([
						origem,
						destino,
						pontoInicio,
						pontoFim,
						turf.point(
							[
								Object.values(terminais.dados)[0].longitude,
								Object.values(terminais.dados)[0].latitude,
							],
							{ id: 'TERMINAL' }
						),
					]),
					destino: places.destino.center,
					pontos: [pontoInicio.properties.id, pontoFim.properties.id],
					linhas: uniq([...linhasPontoInicio, ...linhasPontoFim]),
				});
				aoFechar();
			}
		}
	}, [localizacao, places, pontos, terminais, linhas, setTrajetoria, aoFechar]);

	return (
		<SearchContainer
			pose={aberto === true ? 'aberto' : 'fechado'}
			display="flex"
			flexDirection="column"
			position="fixed"
			top="0"
			left="0"
			right="0"
			maxHeight="100%"
			zIndex="6000"
		>
			<Paper
				elevation={16}
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexShrink: '0',
					position: 'relative',
					boxShadow: 1,
					zIndex: '5',
					padding: '0.5rem',
					borderRadius: 1,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				}}
			>
				<Box display="flex" alignItems="center">
					<SvgIcon
						inheritViewBox
						component={IconInicoFim}
						sx={{
							mr: 1,
							height: '100%',
							width: 'auto',
						}}
					/>

					{/* <Box clone m="0.25rem" height="1.5rem">
						<img src={IconSwap} alt="" />
					</Box> */}
				</Box>

				<Box flex={1}>
					<SearchInput
						label="De:"
						placeholder="origem"
						value={inputs.origem}
						onChange={handleChangeFunc('origem')}
					/>

					<SearchInput
						label="Para:"
						placeholder="destino"
						value={inputs.destino}
						onChange={handleChangeFunc('destino')}
					/>
				</Box>

				<SvgIcon
					inheritViewBox
					component={IconX}
					onClick={aoFechar}
					sx={{
						ml: '0.25rem',
						height: '2rem',
						width: 'auto',
					}}
				/>
			</Paper>

			<ResultContainer
				pose={placeSearch.length > 0 ? 'aberto' : 'fechado'}
				elevation={12}
				sx={{
					flex: 1,
					mt: '-0.5rem',
					pt: '0.5rem',
					position: 'relative',
					boxShadow: 1,
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				}}
			>
				<Box
					flex={1}
					sx={{ overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}
				>
					{placeSearch.map((p) => {
						let textoSecundarioArray;
						try {
							textoSecundarioArray = p.context.slice(-3).map((c) => c.text);
						} catch (er) {
							return null;
						}

						if (p.properties.address) {
							textoSecundarioArray = [
								p.properties.address,
								...textoSecundarioArray,
							];
						}

						return (
							<Box
								px={3}
								my={3}
								key={p.id}
								onClick={() => {
									setPlaces({ destino: p });
									setInputs({ destino: p.text });
									setPlaceSearch([]);
								}}
							>
								<Box
									fontWeight="bold"
									sx={{
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
									}}
								>
									{p.text}
								</Box>
								<Box
									sx={{
										overflow: 'hidden',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
									}}
								>
									{textoSecundarioArray.join(', ')}
								</Box>
							</Box>
						);
					})}
				</Box>
			</ResultContainer>
		</SearchContainer>
	);
};

export default DestinoSearch;
