import React from 'react';

import { Link } from 'react-router-dom';

import { Button, Box, SvgIcon } from '@mui/material';

import { ReactComponent as IconAppOlaOnibus } from '../images/icone-app-ola-onibus.svg';

const ModalTermos = ({ onClose, ...props }) => (
	<Box
		bgcolor="background.default"
		display="flex"
		flexDirection="column"
		justifyContent="center"
		alignItems="center"
		py={0}
		px={2.5}
		height="100%"
		width="100%"
		position="fixed"
		top="0"
		zIndex="6000"
		textAlign="justify"
		{...props}
	>
		<SvgIcon
			inheritViewBox
			component={IconAppOlaOnibus}
			sx={{ m: 1, my: 3, height: 'auto', width: '16rem' }}
		/>

		<Box sx={{ overflow: 'auto' }}>
			<h5>Termos de Uso e Politica de privacidade “OLÁ ÔNIBUS”</h5>

			<b>1 Relacionamento Contratual: </b>
			<p>
				Estes Termos de uso (“Termos”) regem seu acesso e uso, como pessoa
				física, dentro do Brasil, de aplicativos, sítios de Internet, conteúdos,
				bens e também serviços (os “Serviços”) disponibilizados pela Olá Ônibus.
			</p>
			<b>
				POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS
				SERVIÇOS.
			</b>
			<p>
				Ao acessar e usar os Serviços você concorda com os presentes termos e
				condições, que estabelecem o relacionamento contratual entre você e a
				Olá Ônibus. Se você não concorda com estes Termos, você não pode acessar
				nem usar os Serviços. Mediante referido acesso e uso, estes Termos
				imediatamente encerram, substituem e superam todos os acordos, Termos e
				acertos anteriores entre você e as aplicações referentes ao Olá Ônibus.
				A Olá Ônibus poderá imediatamente encerrar estes Termos ou quaisquer
				Serviços em relação a você ou, de modo geral, deixar de oferecer ou
				negar acesso aos Serviços ou a qualquer parte deles, a qualquer momento
				e por qualquer motivo.
			</p>
			<p>
				Termos adicionais poderão se aplicar a determinados Serviços, tais como
				condições para um evento, atividade ou promoção em particular, e esses
				Termos adicionais serão divulgados em relação aos respectivos Serviços.
				Termos adicionais são complementares e considerados parte integrante
				destes Termos para os efeitos dos respectivos Serviços. Termos
				adicionais prevalecerão sobre estes Termos em caso de conflito com
				relação aos referidos Serviços.
			</p>
			<p>
				A Olá Ônibus poderá alterar os Termos relativos aos Serviços a qualquer
				momento. Aditamentos entrarão em vigor quando A Olá Ônibus fizer a
				postagem da versão atualizada dos Termos neste local ou das condições
				atualizadas ou Termos adicionais sobre o respectivo Serviço. O fato de
				você continuar a acessar ou usar os Serviços após essa postagem
				representa seu consentimento em vincular-se aos Termos alterados.
			</p>

			<b>2. OS SERVIÇOS</b>

			<p>
				Os Serviços integram uma plataforma de tecnologia Colaborativa que
				permite aos(às) Usuários(as) de aplicativos móveis ou sítios de Internet
				da Olá Ônibus, fornecidos como parte dos Serviços (cada qual um
				“Aplicativo”), localizar; pontos, terminais, locais de recarga e compra
				de vale transporte, rotas, localizações em tempo real de veículos
				pertencentes ao transporte urbano e horários referentes ao transporte.
				VOCÊ RECONHECE QUE A OLÁ ÔNIBUS NÃO É FORNECEDORA DE BENS, NÃO PRESTA
				SERVIÇOS DE TRANSPORTE OU LOGÍSTICA, NEM FUNCIONA COMO TRANSPORTADORA, E
				QUE TODOS ESSES SERVIÇOS DE TRANSPORTE OU LOGÍSTICA SÃO PRESTADOS POR
				PRESTADORES TERCEIROS INDEPENDENTES QUE NÃO SÃO CONTRATADOS(AS) E NEM
				REPRESENTANTES DA OLÁ ÔNIBUS.
			</p>
			<b>Sobre os dados fornecidos nas aplicações:</b>
			<p>
				Todos os dados fornecidos aos usuários, são coletados a partir da base
				de dados de empresas terceiras não vinculadas ou sem qualquer vinculo
				legal com a Olá Ônibus. Não nos responsabilizamos por fornecimento de
				Dados, informações que estejam equivocadas ou com divergências das
				informações e dados reais. A OLÁ ÔNIBUS SE COLOCA NO TOTAL DIREITO DE
				ALTERAR, EXCLUIR, INCLUIR, MODIFICAR QUALQUER DADO OU INFORMAÇÕES EM
				SUAS APLICAÇÕES OU SITIOS DA INTERNET.
			</p>
			<b>LICENÇA.</b>
			<p>
				Sujeito ao cumprimento destes Termos, a Olá Ônibus outorga a você uma
				licença limitada, não exclusiva, não passível de sublicença, revogável e
				não transferível para: (i) acesso e uso dos Aplicativos em seu
				dispositivo pessoal, exclusivamente para o seu uso dos Serviços; e (ii)
				acesso e uso de qualquer conteúdo, informação e material correlato que
				possa ser disponibilizado por meio dos Serviços, em cada caso, para seu
				uso pessoal, nunca comercial.
			</p>
			<p>
				Quaisquer direitos não expressamente outorgados por estes Termos são
				reservados à Olá Ônibus e suas afiliadas licenciadoras.
			</p>

			<b>RESTRIÇÕES.</b>
			<p>
				Você não poderá: (i) remover qualquer aviso de direito autoral, direito
				de marca ou outro aviso de direito de propriedade de qualquer parte dos
				Serviços; (ii) reproduzir, modificar, preparar obras derivadas,
				distribuir, licenciar, locar, vender, revender, transferir, exibir,
				veicular, transmitir ou, de qualquer outro modo, explorar os Serviços,
				exceto da forma expressamente permitida pela Olá Ônibus; (iii)
				decompilar, realizar engenharia reversa ou desmontar os Serviços, exceto
				conforme permitido pela legislação aplicável; (iv) conectar, espelhar ou
				recortar qualquer parte dos Serviços; (v) fazer ou lançar quaisquer
				programas ou scripts com a finalidade de fazer scraping, indexação,
				pesquisa ou qualquer outra forma de obtenção de dados de qualquer parte
				dos Serviços, ou de sobrecarregar ou prejudicar indevidamente a operação
				e/ou funcionalidade de qualquer aspecto dos Serviços; ou (vi) tentar
				obter acesso não autorizado aos Serviços ou prejudicar qualquer aspecto
				dos Serviços ou seus sistemas ou redes correlatas.
			</p>
			<b>TITULARIDADE.</b>
			<p>
				Os Serviços e todos os direitos sobre eles são e permanecerão de
				propriedade da Olá Ônibus. Estes Termos e o uso dos Serviços não lhe
				outorgam nem lhe conferem qualquer direito: (i) sobre os Serviços,
				exceto pela licença limitada concedida acima; ou (ii) de usar ou, de
				qualquer modo, fazer referência a nomes societários, logotipos, nomes de
				produtos ou de Serviços, marcas comerciais ou marcas de serviço da Olá
				Ônibus ou de qualquer licenciadora da Olá Ônibus.
			</p>

			<b>3. O USO DOS SERVIÇOS</b>
			<br />
			<b>CONDUTA E OBRIGAÇÕES DO USUÁRIO.</b>
			<p>
				Você não poderá ceder, nem de qualquer outro modo transferir, sua Conta
				a nenhuma outra pessoa ou entidade. Você concorda em cumprir todas as
				leis aplicáveis quando usar os Serviços e que somente poderá usar os
				Serviços para finalidades legítimas (por ex. não transportar materiais
				ilegais ou perigosos). Você não poderá, quando usar os Serviços, causar
				transtorno, aborrecimento, inconveniente ou danos à propriedade dos
				Prestadores Terceiros ou de qualquer outro terceiro. Em determinadas
				situações, você poderá ser solicitado(a) a fornecer comprovante de
				identidade para acessar ou usar os Serviços, e concorda que poderá ter
				seu acesso ou uso dos Serviços negado caso você se recuse a fornecer
				comprovante de identidade.
			</p>
			<b>CONTEÚDO FORNECIDO PELO(A) USUÁRIO(A).</b>
			<p>
				A Olá Ônibus poderá, a seu exclusivo critério, permitir que você ou
				qualquer pessoa apresente, carregue, publique ou, de qualquer modo,
				disponibilize para a Olá Ônibus por meio dos Serviços, conteúdo e
				informações de texto, áudio ou vídeo, inclusive comentários e feedbacks
				relacionados aos Serviços, iniciação de solicitação de suporte e
				registro em concursos e promoções (“Conteúdo de Usuário(a)"). Qualquer
				Conteúdo de Usuário(a) fornecido por você permanece de sua propriedade.
				Contudo, ao fornecer Conteúdo de Usuário(a) para a Olá Ônibus, você
				outorga a Olá Ônibus uma licença em nível mundial, perpétua,
				irrevogável, transferível, isenta de royalties, e com direito a
				sublicenciar, usar, copiar, modificar, criar obras derivadas,
				distribuir, publicar, exibir, executar em público e, de qualquer outro
				modo, explorar esse Conteúdo de Usuário(a) em todos os formatos e canais
				de distribuição hoje conhecidos ou desenvolvidos no futuro (inclusive em
				conexão com os Serviços e com os negócios da Olá Ônibus e em sites e
				Serviços de terceiros), sem ulterior aviso a você ou seu consentimento,
				e sem necessidade de pagamento a você ou a qualquer outra pessoa ou
				entidade.
			</p>
			<p>
				Você declara e garante que: (i) é o(a) único(a) e exclusivo(a)
				proprietário(a) de todo Conteúdo de Usuário(a) ou tem todos os direitos,
				licenças, consentimentos e liberações necessários para outorgar à Olá
				Ônibus a licença sobre o Conteúdo de Usuário(a) acima referido; e (ii)
				nem o Conteúdo de Usuário(a) nem sua apresentação, carregamento,
				publicação ou outra forma de disponibilização desse Conteúdo de
				Usuário(a) tampouco o uso do Conteúdo de Usuário(a) pela Olá Ônibus da
				forma aqui permitida infringirá, constituirá apropriação indevida nem
				violará propriedade intelectual ou direito de propriedade de
				terceiros(a), nem direitos de publicidade ou privacidade e também não
				resultarão na violação de qualquer lei ou regulamento aplicável.
			</p>
			<p>
				Você concorda em não fornecer Conteúdo de Usuário(a) que seja
				difamatório, calunioso, injurioso, violento, obsceno, pornográfico,
				ilegal ou de qualquer modo ofensivo, conforme apuração da Olá Ônibus a
				seu critério exclusivo, seja ou não esse material protegido por lei. A
				Olá Ônibus poderá, mas não está obrigada a,analisar, monitorar ou
				remover Conteúdo de Usuário(a), a critério exclusivo da Olá Ônibus, a
				qualquer momento e por qualquer motivo, sem nenhum aviso a você.
			</p>
			<p>
				A Olá Ônibus não se responsabiliza por qualquer dano físico e ou
				material, perca, ou lesa sofrida por utilizar qualquer uma das
				informações obtidas nas nossas aplicações ou sítios da internet. Todos
				os danos devem ser cobrados ou solicitados as empresas prestadoras dos
				serviços de transporte urbano.
			</p>
			<b>ACESSO À REDE E EQUIPAMENTOS.</b>
			<p>
				Você é responsável por obter o acesso a rede de dados necessário para
				usar os Serviços. As taxas e encargos de sua rede de dados e mensagens
				poderão se aplicar se você acessar ou usar os Serviços de um dispositivo
				sem fio e você será responsável por essas taxas e encargos. Você é
				responsável por adquirir e atualizar os equipamentos e dispositivos
				necessários para acessar e usar os Serviços e Aplicativos e quaisquer de
				suas atualizações. A OLÁ ÔNIBUS NÃO GARANTE QUE OS SERVIÇOS, OU QUALQUER
				PARTE DELES, FUNCIONARÃO EM QUALQUER EQUIPAMENTO OU DISPOSITIVO EM
				PARTICULAR. Além disso, os Serviços poderão estar sujeitos a mau
				funcionamento e atrasos inerentes ao uso da Internet e de comunicações
				eletrônicas.
			</p>
			{/*
			4. LEGISLAÇÃO APLICÁVEL; JURISDIÇÃO.
			Estes Termos serão regidos e interpretados exclusivamente de acordo com as leis do Brasil. Qualquer reclamação, conflito ou controvérsia que surgir deste contrato ou a ele relacionada, inclusive que diga respeito a sua validade, interpretação ou exequibilidade, será solucionada exclusivamente pelos tribunais do foro de seu domicílio.
			*/}
		</Box>

		<Button
			variant="contained"
			component={Link}
			to="/"
			onClick={onClose ? onClose : undefined}
			sx={{ m: 1, flex: 'none' }}
		>
			Fechar
		</Button>
	</Box>
);

export default ModalTermos;
