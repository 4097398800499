import React from 'react';
import { useGlobal } from 'reactn';
import { Layer, Feature } from 'react-mapbox-gl';
import TerminalIcon from '../images/icone-terminal.svg';
import { useHistory } from 'react-router-dom';

const terminalImg = new Image(778, 752);
terminalImg.src = TerminalIcon;

const LayerTerminais = () => {
	const [terminais] = useGlobal('terminais');

	const history = useHistory();

	return (
		<Layer
			type="symbol"
			id="terminais"
			layout={{
				'icon-image': 'TerminalIcon',
				'icon-size': [
					'interpolate',
					['exponential', 1],
					['zoom'],
					0,
					0.035,
					11,
					0.045,
					22,
					0.055
				],
				'icon-allow-overlap': true
			}}
			images={['TerminalIcon', terminalImg]}
		>
			{!terminais.loading &&
				Object.values(terminais.dados).map(terminal => (
					<Feature
						key={terminal.id}
						coordinates={[terminal.longitude, terminal.latitude]}
						onClick={() => history.push('/ponto/TERMINAL')}
					/>
				))}
		</Layer>
	);
};

export default LayerTerminais;
