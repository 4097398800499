import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button, Box, TextField, SvgIcon } from '@mui/material';

import { ReactComponent as IconAppOlaOnibus } from '../images/icone-app-ola-onibus.svg';

import { getFirestore, collection, addDoc } from 'firebase/firestore';

const ModalFeedBack = (props) => {
	const history = useHistory();

	const [tipo, setTipo] = useState('');
	const [mensagem, setMensagem] = useState('');
	const [loading, setLoading] = useState(false);

	function enviarFeedBack() {
		if (tipo && mensagem.split(' ').length > 0) {
			setLoading(true);
			return addDoc(collection(getFirestore(), 'users'))
				.then(() => {
					alert('Obrigado pelo FeedBack !');
					setTipo('');
					setMensagem('');
					setLoading(false);
					history.push('/');
				})
				.catch((er) => alert(er));
		} else {
			alert('Preencha os campos');
		}
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			alignItems="center"
			bgcolor="background.default"
			height="100%"
			width="100%"
			position="fixed"
			top="0"
			zIndex="5000"
			p={0}
			px={2}
			textAlign="center"
			component="form"
			{...props}
		>
			<SvgIcon
				inheritViewBox
				component={IconAppOlaOnibus}
				sx={{ mt: 2, height: 'auto', width: [200, 400] }}
			/>

			<Box>
				<TextField
					fullWidth
					select
					SelectProps={{
						native: true,
					}}
					name="tipoFeedback"
					id="tipoFeedback"
					label="Qual o tipo do feedback ?"
					value={tipo}
					onChange={(e) => setTipo(e.target.value)}
					margin="normal"
				>
					<option value="" />
					<option value="FALTA_PONTO">Falta de pontos</option>
					<option value="ROTA_INCORRETA">Rota Incorreta</option>
					<option value="BAIXA_PERFORMANCE">Performance do App</option>
					<option value="OUTRO">Outro</option>
				</TextField>

				<TextField
					fullWidth
					multiline
					variant="outlined"
					label="Mensagem"
					rows="6"
					value={mensagem}
					onChange={(e) => setMensagem(e.target.value)}
				/>

				<Button
					fullWidth
					variant="contained"
					onClick={() => enviarFeedBack()}
					sx={{ my: 1 }}
				>
					{loading ? 'Enviando ...' : 'Enviar'}
				</Button>
			</Box>

			<Box>
				<Button
					variant="contained"
					component={Link}
					to="/"
					sx={{ m: 2, textDecoration: 'none' }}
				>
					Fechar
				</Button>
			</Box>
		</Box>
	);
};

export default ModalFeedBack;
